/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --content-max-width: 100%;
  --grid-column: 8.333333%;
  --grid-row: 8.333333vh;
  --grid-gutter: 1.5rem;
  --border-radius: 0;
  --modal-top-margin: 1.1rem;

  /* Colors */
  --color-dark: #181818;
  --color-dark-5: rgba(24,24,24,0.05098);
  --color-dark-10: rgba(24,24,24,0.10196);
  --color-dark-20: rgba(24,24,24,0.2);
  --color-dark-50: rgba(24,24,24,0.50196);

  --color-light: #FDFDFD;

  --color-red-light: #F6D6D2;
  --color-orange-light: #FFEDDA;
  --color-beige: #FFFBE9;
  --color-green-light: #DAFFEF;
  --color-blue-light: #D2DFF6;
  --color-pink: #FF62FF;
  --color-yellow: #FFD300;
  --color-green: #1CD286;
  --color-blue: #0000FF;

  /* Set the default font to use on the site */
  --font-family-noto: 'Roboto',"Helvetica Neue", -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,sans-serif;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;


  /* Set the default font size */
  --font-size-small: 13px;
  --font-size-cta: 14px;
  --font-size-body: 16px;
  --font-size-md: 1.5rem;
  --font-size-lg: 2.25rem;
  --font-size-xl: 3rem;

  --font-lineheight-body: 1.5em;
  --font-lineheight-headers: 1.2em;

  --border: 2px solid currentColor;

  --opacity-dim: .7;
  --opacity-font: .5;
  --opacity-border: .2;
  --opacity-border-dimmed: .1;
  --opacity-background: .05;


  --filter-box-shadow: drop-shadow(0px 3px 9px rgba(0, 0, 0, .07));

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);



  --z-index-context-menu: 98;
  --z-index-map-meta: 99;
  --z-index-header: 100;
  --z-index-map-settings: 101;
  --z-index-create-map: 102;
}
.Home {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.Home:before {
    z-index: 0;
    position: absolute;
    content: '';
    background-color: #FFD300;
    background-color: var(--color-yellow);
    opacity: .1;
    pointer-events: none;
    top: .75rem;
    right: .75rem;
    bottom: .75rem;
    left: .75rem;
  }
@media (min-width: 768px) {
.Home:before {
      top: 1rem;
      right: 1rem;
      bottom: 1rem;
      left: 1rem
  }
    }
.Home [class*="container"] {
    position: relative;
  }
.video {
  z-index: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 100%;
}
@media (min-width: 1024px) {
.video {
    position: absolute;
    right: 0;
    bottom: 0;
    height: auto;
    min-height: 100%
}
  }
.videoPoster {
  composes: video;
  -o-object-fit: cover;
     object-fit: cover;
}
.heart {
  z-index: 1;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  height: auto;
  width: 4vw;
}
@media (min-width: 768px) {
.heart {
    width: 2vw
}
  }
.logo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  margin: 12vh 0 16vh 0;
}
@media (min-width: 768px) {
.logo {
    margin: 8vh 0 12vh 0
}
  }
.linkSignup {
  text-transform: uppercase;
  text-decoration: underline;
}
.header img {
    width: 100%;
    height: auto;
  }
.header h1 {
    margin: 2rem 0 0 0;
  }
.header p {
    text-transform: uppercase;
    opacity: .7;
  }
.releases {
  margin: 5vh 0;
}
.covers {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
}
.covers .cover {
    margin-bottom: 2rem;
  }
.covers .cover h2 {
      margin-bottom: 0;
    }
.covers .cover a {
      margin: .5em 0;
    }
.covers .cover img {
      width: 45vw;
      min-width: 45vw;
      height: auto;
    }
.covers .cover:not(:last-child) {
      margin-right: 4vw;
    }
.covers .cover:not(:last-child) img {
        filter: none;
      }
.cover {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cover h3 {
    margin-bottom: 0;
  }
.cover img {
    margin-top: .75rem;
  }