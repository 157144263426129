/* Define Custom Media queries */
/* Define global CSS variables inside :root */
:root {
  --content-max-width: 100%;
  --grid-column: 8.333333%;
  --grid-row: 8.333333vh;
  --grid-gutter: 1.5rem;
  --border-radius: 0;
  --modal-top-margin: 1.1rem;

  /* Colors */
  --color-dark: #181818;
  --color-dark-5: rgba(24,24,24,0.05098);
  --color-dark-10: rgba(24,24,24,0.10196);
  --color-dark-20: rgba(24,24,24,0.2);
  --color-dark-50: rgba(24,24,24,0.50196);

  --color-light: #FDFDFD;

  --color-red-light: #F6D6D2;
  --color-orange-light: #FFEDDA;
  --color-beige: #FFFBE9;
  --color-green-light: #DAFFEF;
  --color-blue-light: #D2DFF6;
  --color-pink: #FF62FF;
  --color-yellow: #FFD300;
  --color-green: #1CD286;
  --color-blue: #0000FF;

  /* Set the default font to use on the site */
  --font-family-noto: 'Roboto',"Helvetica Neue", -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,sans-serif;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;


  /* Set the default font size */
  --font-size-small: 13px;
  --font-size-cta: 14px;
  --font-size-body: 16px;
  --font-size-md: 1.5rem;
  --font-size-lg: 2.25rem;
  --font-size-xl: 3rem;

  --font-lineheight-body: 1.5em;
  --font-lineheight-headers: 1.2em;

  --border: 2px solid currentColor;

  --opacity-dim: .7;
  --opacity-font: .5;
  --opacity-border: .2;
  --opacity-border-dimmed: .1;
  --opacity-background: .05;


  --filter-box-shadow: drop-shadow(0px 3px 9px rgba(0, 0, 0, .07));

  --cubic-ease-in-out: cubic-bezier(.5, 0, .5, 1);
  --cubic-ease-out: cubic-bezier(.5, 1, .5, 1);



  --z-index-context-menu: 98;
  --z-index-map-meta: 99;
  --z-index-header: 100;
  --z-index-map-settings: 101;
  --z-index-create-map: 102;
}
.TermsConditions {
  width: 100%;
  padding: 8.333333vh 0;
  padding: var(--grid-row) 0;
}
